export function loadGTM (gtmId, gtmLayerName = "dataLayer") {
   
  ((w, d, s, l, i) => {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", gtmLayerName, gtmId);
   

  const noScriptGTM = document.createElement("noscript");
  noScriptGTM.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
  document.body.prepend(noScriptGTM);
}

if (import.meta.client && import.meta.env.PROD) { loadGTM("GTM-P26XNK28"); }
